<template>
  <div class="group_critical_summary">
    <div class="box__summary total">{{ total }}</div>
    <div class="box__summary bsd">{{ bsd }}</div>
    <div class="box__summary bfd">{{ bfd }}</div>
    <div class="box__summary og">{{ og }}</div>
    <div class="box__summary complete">{{ complete }}</div>
  </div>
</template>

<script>
export default {
  name: 'k-p6--group-critical-summary',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    total() { return this.item.TOTAL },
    bsd() { return this.item.BEHIND_START_DATE },
    bfd() { return this.item.BEHIND_FINISH_DATE },
    og() { return this.item.ON_GOING },
    complete() { return this.item.COMPLETED },
  },
  mounted() {
    // console.log(this.item)
  }
}
</script>

<style lang="stylus" scoped>
.group_critical_summary {
  display: flex !important;
  margin-left: 1rem;
}
.box__summary {
  display: flex !important;
  height: 1.6rem;
  line-height: 1.6rem;
  width: 3rem;
  text-align: center;
  margin: 0 .1rem;
  border-radius: 3px;
  color: #454545;
  font-size: .9rem;
  font-weight: 400;

  &.total {
    background-color: #fff;
  }
  &.bsd {
    background-color: #9693fc;
  }
  &.bfd {
    background-color: #ffc107;
  }
  &.og {
    background-color: #64d969;
  }
  &.complete {
    background-color: #959595;
  }
}
</style>