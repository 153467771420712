import { DBSupportService } from '../../../src/services'
import GanttSchedule from '../../jin/svgchartlib/01_Charts/99_Common/Timelines/SingleItemSchedule'

export default {
  name: 'kim-p6-activities',
  props: {
    tail: Boolean,
    parent: null,
    target: String,
    envs: null,
    p6props: null,
  },
  data: () => ({
    queryLibService: null,
    tableActivities: null,
  }),
  computed: {
    border() { 
      let lastLv = Object.keys(this.p6props.levels).pop()
      return this.p6props.levels[lastLv].style.border
    }
  },
  watch: {
    'parent.items': { 
      handler() {
        if(!this.tail) this.genActivities()
      },
      deep: true
    }
  },
  created() {
    this.queryLibService = new DBSupportService()
  },
  mounted() {
    // sql query to get activities sould be executed only for Not Tail.
    // when tail is true, activities sould be passed from event 'activity-retrieved' of the Not Tail
    if(!this.tail) this.genActivities()
  },
  methods: {
    genActivities(acts=null) {
      if(!this.p6props.activityQeuryName) {
        console.error(`[USER: #DEBUG] The p6 activity query is not defined. Can't create the P6 Activity.`)
        this.tableActivities = null
        return
      }

      let data = {
        class: {
          j_intended: true,
          j_datatable_grouped_column: true,
          sticky_header: true, // to make the table border smooth (not dashed-like)
        },
        style: {
          border: this.border,
        }
      }
      if(this.tail) data.style.marginLeft = '1px'

      if(acts) {
        if(acts.length === 0) this.tableActivities = null
        else this.tableActivities = [this.$createElement('table', data, [this.genHeader(), this.genBody(acts)])]

      } else {
        let fString = [`CONCAT(${Object.keys(this.p6props.levels).join(', ')}) = '${this.target}'`]
        if(this.parent.filterString) fString.push(this.parent.filterString)

        this.queryLibService.getSqlQueryResult({
          idx         : 0, 
          name        : this.p6props.activityQeuryName,
          filterType  : 'json',
          filters     : this.parent.filteredValues ? JSON.stringify(this.parent.filteredValues) : '{}',
          filterString: fString.join(' AND '),
          jsonProps   : JSON.stringify(this.parent.headers),
          inputFilter : this.parent.inputFilter,
        }).then(activities => {
          if(!activities || activities.length === 0) {
            this.$emit('activity-retrieved', [])
            this.tableActivities = null
            return 
          }

          this.$emit('activity-retrieved', activities)
          this.tableActivities = [this.$createElement('table', data, [this.genHeader(), this.genBody(activities)])]
        })
      }
    },
    genHeader() {
      let snames = Object.keys(this.envs.stickyColumns)                 // sticky col names
      let hnames = this.parent.getHeaderNames(this.envs.siftedHeaders)  // all col names
      let tnames = hnames.filter(name => !snames.includes(name))        // tail col names
      let names_ = this.tail ? tnames : (snames.length>0 ? snames : hnames)

      let ths = names_.map((colName, i) => {
        let class_ = {}
        let style_ = {}
        let h_ = this.envs.easyFormedHeaders[colName]
        let w_ = h_.width + (i?0:1)

        if (colName == undefined || h_.type == 'spacer') style_['width'] = w_ ? `${w_}px` : '100%' 
        else if(h_.type == 'gantt') class_.gantt_timeline = true
        else {
          style_['width'] = `${w_}px`
          style_['max-width'] = `${w_}px`
          style_['min-width'] = `${w_}px`
        }

        return this.$createElement('th', { class: class_, style: style_ })
      })

      return this.$createElement('thead', [this.$createElement('tr', { style: { height: 0 }}, ths)])
    },
    genBody(items) {
      let trdata = {
        class: {
          tr_mouseoverable: true,
        },
        style: {
          'height': `${this.parent.tableAttrs.body.cell.height}px`
        },
      }
      return this.$createElement('tbody', items.map((item, i) => this.$createElement('tr', {
        attrs: { id: `tr_${this.target.replace(/[^0-9a-zA-Z]/g, '_')}_${i}`},
        ...trdata,
        on: {
          mouseover: () => { this.$emit('tr-mouseover', `tr_${this.target.replace(/[^0-9a-zA-Z]/g, '_')}_${i}`) },
          mouseout: () => { this.$emit('tr-mouseout', `tr_${this.target.replace(/[^0-9a-zA-Z]/g, '_')}_${i}`) },
        }
      }, this.genTDs(item))))
    },
    genTDs(item) {
      let snames = Object.keys(this.envs.stickyColumns)                 // sticky col names
      let hnames = this.parent.getHeaderNames(this.envs.siftedHeaders)  // all col names
      let tnames = hnames.filter(name => !snames.includes(name))        // tail col names
      let names_ = this.tail ? tnames : (snames.length>0 ? snames : hnames)

      return names_.map((colName, i) => this.genTD(item, colName, false, i))
    },
    genTD(item, colName) {
      let columnInfo_ = this.envs.easyFormedHeaders[colName]
      if(!columnInfo_) {
        console.error(`[USER: undefined] Invalid column name, ${colName}`)
        return this.$createElement('td', [''])
      }

      let bodyProps_ = this.parent.tableAttrs.body
      let cellProps_ = this.parent.tableAttrs.body.cell
      let style_ = {}

      // Set Style -----------------------
      this.parent.getStyleBorder(style_, colName, 'cell')

      style_['font-family'] = cellProps_.font
      style_['font-size'] = `${cellProps_.fontSize}px`
      style_['color'] = cellProps_.color

      let dataTD = { style: style_ }

      // set tooltip
      if(columnInfo_.nowrap) dataTD.attrs = { title: item[colName] }
      if(columnInfo_.indented) dataTD.style['padding-left'] = `${item[columnInfo_.levelColName]*15}px`
  
      let default_ = (gantt=false) => {
        let ganttData = {
          props: {
            item      : item,
            size      : columnInfo_.size,
            keyName   : columnInfo_.keyColName,
            syncparent: this.parent.ganttInfo
          }
        }
        let elTexts_ = gantt ? [this.$createElement(GanttSchedule, ganttData)] : this.parent.setText(item, colName)
        return this.$createElement('td', dataTD, elTexts_)
      }

      let td___ = null
      if(this.parent.styleAttrAvailable && columnInfo_.styleAttr) {
        let styleAttr = columnInfo_.styleAttr
        let colNameCode = (
          !columnInfo_.styleAttrData.code ||
          columnInfo_.styleAttrData.code == 'self' ?
          colName :
          columnInfo_.styleAttrData.code
        )

        if(!item[colNameCode]) {
          console.log(`[USER: column code '${colNameCode}' undefined] Cannot find a column code to be matched with style attribute.`)
          td___ = default_()

        } else if(!this.parent.codeAttrAvailable('style', columnInfo_.styleAttr, item[colNameCode])) {
          console.log(`[USER: Not found] Cannot find style attribute for CODE-VALUE '${item[colNameCode]}'.`)
          td___ = default_()

        } else {
          let codeAttr_ = this.parent.tableAttrs.style[styleAttr].find(style => style.code == item[colNameCode])
          let styledContent_ = this.parent.genStyleAttrContent(item, colName, styleAttr, codeAttr_)
          td___ = this.$createElement('td', dataTD, styledContent_)
        }

      } else if(this.parent.svgAttrAvailable && columnInfo_.svgAttr) {
        let svgAttr = columnInfo_.svgAttr
        let colNameCode = (
          !columnInfo_.svgAttrData.code ||
          columnInfo_.svgAttrData.code == 'self' ?
          colName :
          columnInfo_.svgAttrData.code
        )

        if(!item[colNameCode]) {
          console.log(`[USER: column code '${colNameCode}' undefined] cannot find a column code to be matched with svg style attribute.`)
          td___ = default_()

        } else if(!this.parent.codeAttrAvailable('svg', columnInfo_.svgAttr, item[colNameCode])) {
          console.log(`[USER: Not found] Cannot find SVG style attribute for CODE-VALUE '${item[colNameCode]}'.`)
          td___ = default_()

        } else {
          let codeAttr_ = this.parent.tableAttrs.svg[svgAttr].attrs.find(svg => svg.code == item[colNameCode])
          let svgContent_ = this.parent.genSvgAttrContent(item, colName, svgAttr, codeAttr_)
          td___ = this.$createElement('td', dataTD, svgContent_)
        }
      } else td___ = default_(columnInfo_.type == 'gantt')

      return td___
    },
  },
  render(h) {

    /* ### CAUSTION ############################################
     * Don't put any code in the render function, it causes the 
     * infinite loop occurs.
     * ######################################################### */

    return h('div', { class: 'j_datagrid' }, [this.tableActivities])
  }
}